import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShieldAlt,
  faAt,
  faNetworkWired,
  faEnvelopeOpenText,
  faIdBadge,
  faKey,
  faSearch,
  faSyncAlt,
  faInfoCircle,
  faExchangeAlt,
  faUserSecret,
  faGlobeAmericas,
  faBan,
  faServer,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

const FeatureOverview = () => {
    return (
        <div className="bg-white text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div id="tools" className="flex flex-wrap -m-4">
                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faShieldAlt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/cert">TLS Certificates & Ciphers</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Ensure your site's security with a detailed TLS setup examination.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faAt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/mx-lookup">MX Records Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Verify email server configurations and ensure reliable delivery.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faServer} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/dns">Authoritative DNS Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Find the authoritative DNS servers for your domain.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                               <Link to="/spf">SPF Records Inspector</Link> 
                            </h2>
                            <p className="leading-relaxed text-base">
                                Check and validate your SPF records to prevent email spoofing.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faIdBadge} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/bimi">BIMI Records Checker</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Confirm your brand identity with BIMI record verification.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faKey} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/dkim">DKIM Records Examiner</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Inspect DKIM records to secure your email against tampering.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faSearch} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/validate-dns">DNS Configuration Verifier</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Ensure your DNS settings are correct and optimized.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faSyncAlt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/ptr">Reverse DNS (PTR) Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Map IP addresses back to their hostnames to check network traffic.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/asn">ASN Info Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Retrieve detailed information on Autonomous System Numbers.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faExchangeAlt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/asntoip">Reverse ASN to IP Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Find all IP ranges associated with a given ASN.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faUserSecret} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/whois">Whois Info Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Access domain registration details for ownership insights.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faGlobeAmericas} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/country">Country from IP Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Identify the country of origin for any IP address.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faBan} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/blacklist">DNS Blacklist Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Check if your domain is listed on DNSBLs and prevent email delivery issues.
                            </p>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faNetworkWired} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/address">Resolve Domain</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Resolve the Internet Protocal (IP) records for a domain.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureOverview;
