import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About NetQuery</title>
        <meta name="keywords" content="About NetQuery" />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 xl:max-w-6xl mx-auto">
        <h2 className="text-2xl font-semibold text-gray-900 leading-tight sm:text-3xl mt-4"><a href="https://accounts.netquery.tools/sign-up">Sign in to access NetQuery!</a></h2>
        <p className="mt-4 text-lg text-gray-600">
          <strong>Get exclusive early access</strong> to NetQuery - network tooling we first designed for ourselves and now are bringing to you.
        </p>
        <p className="mt-4 text-lg text-gray-600">
          NetQuery combines advanced <a href="https://chat.openai.com/g/g-ICxsP3WdV-netquery" className="text-blue-600 hover:underline">GPT-based tech</a> with our powerful APIs to help you explorer improve your network quickly.
        </p>
        <p className="mt-4 text-lg text-gray-600">
          Our tools make it easy to navigate to the next query. Investigate the odd IP in your <Link to="/spf" className="text-blue-600 hover:underline">SPF record</Link> with one click. Find the <Link to="/country" className="text-blue-600 hover:underline">countries</Link> hosting your authoritative DNS servers without using another tool.
        </p>
        <p className="mt-4 text-lg text-gray-600">
          Ready to dive in? <a href="https://accounts.netquery.tools/sign-up" className="text-blue-600 hover:underline">Sign up for free</a> and be part of the exclusive early access group. Your feedback will help us create the ultimate network toolset.
        </p>
        <div className="mt-8 text-gray-600 text-sm">
          <small>This product includes GeoLite2 data created by MaxMind, available at <a href="https://www.maxmind.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">https://www.maxmind.com</a>.</small>
        </div>
      </div>
    </>
  );
};

export default About;
