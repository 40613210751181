import React from 'react';
import { Helmet } from 'react-helmet';

const TOS: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Terms of Service</title>
                <meta name="keywords" content="Terms of Service for NetQuery" />
            </Helmet>
            <div className="p-8 bg-white shadow-md rounded-lg">
                <h2 className="text-2xl font-bold text-gray-900 border-b-2 border-gray-200 pb-4 mb-6">TERMS OF USE</h2>
                <p className="mb-5">
                    Welcome to NetQuery Tools. By accessing or using our website and services,
                    you agree to be bound by the following terms and conditions (the "Terms").
                </p>
                <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4">1. Acceptance of Terms</h3>
                <p className="mb-5">
                    By accessing our website, you acknowledge that you have read, understood,
                    and agree to be bound by these Terms.
                </p>
                <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4">2. Use of Service</h3>
                <p className="mb-5">
                    NetQuery Tools provides tools for making network requests and monitoring performance.
                    These services are provided "as is" without any guarantees or warranty.
                    In association with the product, NetQuery Tools makes no warranties of any kind,
                    either express or implied.
                </p>
                <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4">3. User Conduct</h3>
                <p className="mb-5">
                    You agree not to use the service for any unlawful purpose or in any way that might harm,
                    damage, or disparage any other party.
                </p>
                <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4">4. Limitation of Liability</h3>
                <p className="mb-5">
                    Under no circumstances shall NetQuery Tools be liable for any direct, indirect,
                    incidental, special, or consequential damages that result from the use of or inability
                    to use our services.
                </p>
                <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4">5. Amendments</h3>
                <p className="mb-5">
                    We reserve the right to amend these Terms at any time. Your continued use of the service
                    constitutes acceptance of any such changes.
                </p>
                <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4">6. Contact Information</h3>
                <p className="mb-5">
                    If you have any questions regarding these Terms of Use, please contact us at contact@netquery.tools.
                </p>
            </div>
        </>
    );
};

export default TOS;
