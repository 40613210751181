import React from 'react';
import { Helmet } from 'react-helmet';

const Experimental: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NetQuery - Experimental</title>
        <meta name="keywords" content="NetQuery Experimental Projects" />
      </Helmet>
      <div className="font-sans text-gray-800 p-5 max-w-2xl mx-auto">
        <h2 className="text-blue-600 text-2xl mb-2.5">Experimental Projects - Custom GPTs built on OpenAI</h2>
        <p className="text-base mb-5">At NetQuery, we're excited about AI. This page is where you can access our latest efforts.</p>

        <div className="bg-gray-100 p-4 mb-5 rounded">
          <h3 className="text-blue-800 text-xl mb-1.5">Specialized AI Applications</h3>
          <p className="text-sm mb-2.5">Discover our tailored AI solutions for creative and analytical tasks.</p>
          <ul className="list-none p-0">
            <li><a href="https://chat.openai.com/g/g-stJoea8YF-guitar-tablature" className="text-blue-500 no-underline hover:underline">Guitar Tablature</a> - Generate and refine guitar tabs for popular songs.</li>
            <li><a href="https://chat.openai.com/g/g-JtNBVJbEc-knitting-pattern-maker" className="text-blue-500 no-underline hover:underline">Knitting Pattern Maker</a> - Collaborate and make custom knitting patterns</li>
            <li><a href="https://chat.openai.com/g/g-Ysi3SXVDF-waves-lost-upon-the-shore" className="text-blue-500 no-underline hover:underline">Waves Lost Upon the Shore</a> - Interactive storytelling and narrative exploration.</li>
            <li><a href="https://chat.openai.com/g/g-Vrptf9EVH-textchex" className="text-blue-500 no-underline hover:underline">TextChex</a> - Plagiarism detection and writing style assessment tool.</li>
            <li><a href="https://chat.openai.com/g/g-tr1dK8vJY-travel-inspiration" className="text-blue-500 no-underline hover:underline">Travel Inspiration 🌎</a> - Tell us about your ideal vacation and we'll suggest the perfect place.</li>
          </ul>
        </div>

        <div className="bg-gray-100 p-4 mb-5 rounded">
          <h3 className="text-blue-800 text-xl mb-1.5">Network Tools</h3>
          <p className="text-sm mb-2.5">Explore our innovative tools designed for efficient network management and diagnostics.</p>
          <ul className="list-none p-0">
            <li><a href="https://chat.openai.com/g/g-ICxsP3WdV-netquery" className="text-blue-500 no-underline hover:underline">NetQuery</a> - Perform DNS and MX lookups, SSL certificate queries, ASN queries, IP-to-location conversions, and more.</li>
          </ul>
        </div>

        <div className="bg-gray-100 p-4 mb-5 rounded">
          <h3 className="text-blue-800 text-xl mb-1.5">Historical Figures Interactive Experiences</h3>
          <p className="text-sm mb-2.5">Engage in unique conversational journeys with historical figures, brought to life through primary sources and multimodal AI.</p>
          <ul className="list-none p-0">
            <li><a href="https://chat.openai.com/g/g-zOvdoMER6-james-madison" className="text-blue-500 no-underline hover:underline">James Madison</a> - Delve into the ideas of government and constitution with Madison.</li>
            <li><a href="https://chat.openai.com/g/g-cyVvjW46E-thomas-jefferson" className="text-blue-500 no-underline hover:underline">Thomas Jefferson</a> - Explore Jefferson's thoughts on democracy and liberty.</li>
            <li><a href="https://chat.openai.com/g/g-aVVo4xwvX-john-adams" className="text-blue-500 no-underline hover:underline">John Adams</a> - Investigate Adams' views on politics and law.</li>
            <li><a href="https://chat.openai.com/g/g-wKaOvcsOX-alexander-hamilton" className="text-blue-500 no-underline hover:underline">Alexander Hamilton</a> - Discuss modern issues through Hamilton's 18th-century wisdom.</li>
            <li><a href="https://chat.openai.com/g/g-mZaXzlCbY-george-washington" className="text-blue-500 no-underline hover:underline">George Washington</a> - Engage in discussions on modern technology with Washington.</li>
            <li><a href="https://chat.openai.com/g/g-HqRuKqBwp-napoleon-bonaparte" className="text-blue-500 no-underline hover:underline">Napoleon Bonaparte</a> - Explore modern challenges with Napoleon.</li>
            <li><a href="https://chat.openai.com/g/g-3owy6hCZg-william-shakespeare" className="text-blue-500 no-underline hover:underline">William Shakespeare</a> - Engage in a unique dialogue with Shakespeare on contemporary issues.</li>
            <li><a href="https://chat.openai.com/g/g-d5Xr7KOok-benjamin-franklin" className="text-blue-500 no-underline hover:underline">Benjamin Franklin</a> - Interact with Franklin on modern technology and personal inquiries.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Experimental;