// MXLookup.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QueryForm from '../QueryForm';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import HostRecord from '../HostRecord';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface MXRecordBase {
  exchange: string;
  priority: number;
}

interface MXRecord extends MXRecordBase {
  ARecords: { address: string; ttl: number }[];
  AAAARecords: { address: string; ttl: number }[];
}

interface TestResults {
  dmarcPublished: boolean;
  dmarcPolicy: string;
  subdomainPolicy: string;
  aggregateReportEmail: string;
  forensicReportEmail: string;
  rawDMARCRecord: string;
}

const MXLookup: React.FC = () => {
  const { hostname } = useParams();
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  const [mxRecords, setMxRecords] = useState<MXRecord[]>([]);
  const [testResults, setTestResults] = useState<TestResults>({
    dmarcPublished: false,
    dmarcPolicy: "",
    subdomainPolicy: "",
    aggregateReportEmail: "",
    forensicReportEmail: "",
    rawDMARCRecord: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    async function fetchMXRecords() {
      try {
        const [mxResponse, dmarcResponse, dnsResponse] = await Promise.all([
          fetch('/api/GetMXRecords', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: hostname }),
          }),
          fetch('/api/CheckDMARC', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: hostname }),
          }),
          fetch('/api/ResolveHostname', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: hostname }),
          }),
        ]);

        const mxData = await mxResponse.json();
        const dmarcData = await dmarcResponse.json();
        const dnsData = await dnsResponse.json();

        if (mxData && Array.isArray(mxData.mxRecords)) {
          const updatedMxRecords = mxData.mxRecords.map((record: MXRecordBase) => ({
            ...record,
            ARecords: dnsData.ARecords || [],
            AAAARecords: dnsData.AAAARecords || [],
          }));

          setMxRecords(updatedMxRecords);
          setTestResults({
            dmarcPublished: dmarcData.published || false,
            dmarcPolicy: dmarcData.policy || "",
            subdomainPolicy: dmarcData.subdomainPolicy || "",
            aggregateReportEmail: dmarcData.aggregateReportEmail || "",
            forensicReportEmail: dmarcData.forensicReportEmail || "",
            rawDMARCRecord: dmarcData.rawRecord || "",
          });

        } else {
          console.error('Received data is not in the expected format:', mxData);
        }
      } catch (error) {
        console.error('Failed to fetch MX records:', error);
      } finally {
        setLoading(false);
      }
    }
    if (hostname) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/mx-lookup/${hostname}`,
        });
        return;
      }
      fetchMXRecords();
    }
  }, [hostname, openSignIn, userInfo]);

  const pageTitle = hostname ? `MX Records for ${hostname} - MX Lookup Tool` : "MX Lookup Tool - Check MX Records Easily";
  const pageDescription = "Use our MX Lookup tool to find and analyze Mail Exchange (MX) records for any domain. Ensure your domain's email routing is optimized for reliability and performance.";

  if (!hostname) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content="MX Lookup, DNS Lookup, Email Domain Check, Mail Domain Check, MX Records, Email Routing, Domain MX Check, Find MX Records" />
          <link rel="canonical" href={`https://netquery.tools/mx-lookup/${hostname ? hostname : ''}`} />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faAt} className="text-blue-500" /> Discover MX Records</h1>
            <QueryForm placeholder="Enter a domain..." ariaLabel="Enter a domain for MX lookup" navigatePath={(inputValue) => `/mx-lookup/${inputValue}`} />
            <p></p>
            <p className="mt-2 text-md text-gray-600">
              Our MX Lookup tool is designed to help IT professionals, system administrators, and email marketers quickly retrieve and analyze Mail Exchange (MX) records for any given domain. This essential tool aids in troubleshooting email delivery issues, verifying email service configurations, and ensuring that your email routing is set up correctly.
            </p>
            <section className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800">Why Use an MX Lookup Tool?</h2>
              <p className="mt-2 text-md text-gray-600">
                Understanding the configuration of MX records for a domain is crucial for managing email flow and ensuring emails reach their intended destinations without delay or issues. Our tool provides detailed insights into MX records configuration, including priority levels and associated mail servers.
              </p>
            </section>

            <section className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800">How Does It Work?</h2>
              <p className="mt-2 text-md text-gray-600">
                Simply enter the domain name you wish to check in the form above. Our tool will then query DNS records in real-time and display the MX records associated with the domain, including priority and server details. This information can be used to verify that your MX records are set up as expected or to diagnose mail delivery issues.
              </p>
            </section>

            <section className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800">Benefits of Regular MX Record Checks</h2>
              <ul className="list-disc pl-10 text-md text-gray-600 mt-2">
                <li>Ensure reliable email delivery by verifying MX configurations.</li>
                <li>Diagnose and troubleshoot email delivery issues promptly.</li>
                <li>Verify that email services are correctly configured for new or migrated domains.</li>
                <li>Enhance email security by confirming that MX records are pointing to the correct mail servers.</li>
              </ul>
            </section>
            <div className="max-w-xl lg:max-w-4xl mx-auto mt-12">
              <h2 className="text-4xl font-semibold text-gray-800 mb-6">Frequently Asked Questions</h2>
              <dl className="text-left space-y-4">
                <div>
                  <dt className="font-medium text-gray-800">Can an MX record point to two servers?</dt>
                  <dd className="text-gray-600 mt-1">Yes, MX records can point to multiple servers to provide redundancy and ensure reliable email delivery.</dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">Can an MX record be an IP address?</dt>
                  <dd className="text-gray-600 mt-1">Typically, MX records should point to a hostname rather than a direct IP address. This allows for better management and stability in your email services.</dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">How do MX records work?</dt>
                  <dd className="text-gray-600 mt-1">MX records specify the mail server responsible for receiving email messages on behalf of a domain. They are listed in priority order to direct the flow of mail.</dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">How to perform an MX lookup?</dt>
                  <dd className="text-gray-600 mt-1">You can perform an MX lookup by entering the domain name into our MX Lookup tool, which queries DNS records and returns the MX records associated with the domain.</dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">What are MX records in DNS?</dt>
                  <dd className="text-gray-600 mt-1">MX records in DNS are records that specify the mail server responsible for accepting email messages on behalf of a recipient's domain.</dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">Why might MX record checks fail?</dt>
                  <dd className="text-gray-600 mt-1">MX record checks might fail due to incorrect DNS settings, a non-existent domain, network issues, or misconfigured mail servers.</dd>
                </div>
              </dl>
            </div>


            <p className="mt-6 text-md text-gray-600">
              Ready to explore your domain's MX records? Enter a domain above and start analyzing now.
            </p>
          </div>
        </div>
      </>

    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={`View MX records for ${hostname}. Analyze mail server configurations and ensure optimal email delivery.`} />
      </Helmet>
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faAt} className="text-blue-500" /> MX Records for <HostRecord host={hostname} /></h1>
          {loading ? (
            <div className="flex justify-center items-center space-x-2">
              <FontAwesomeIcon icon={faSpinner} spin className="text-2xl text-blue-500" />
              <p className="text-lg text-gray-600">Loading MX records...</p>
            </div>
          ) : (
            <>
              <div className="my-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">MX Records Details</h3>
                <ul className="list-disc pl-5 text-md text-gray-600">
                  {mxRecords.map((record, index) => (
                    <li key={index}>
                      <HostRecord host={record.exchange} /> (priority: {record.priority})
                    </li>
                  ))}
                </ul>
              </div>
              <div className="my-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">DMARC Analysis</h3>
                <ul className="list-disc pl-5 text-md text-gray-600">
                  <li className="flex items-center">
                    <FontAwesomeIcon icon={testResults.dmarcPublished ? faCheckCircle : faTimesCircle} className={`mr-2 ${testResults.dmarcPublished ? 'text-green-500' : 'text-red-500'}`} />
                    DMARC Record Published: {testResults.dmarcPublished ? 'Yes' : 'No'}
                  </li>
                  <li>DMARC Policy: {testResults.dmarcPolicy || 'Not specified'}</li>
                  <li>DMARC Subdomain Policy: {testResults.subdomainPolicy || 'Not specified'}</li>
                  <li>Aggregate Report Email: {testResults.aggregateReportEmail || 'Not specified'}</li>
                  <li>Forensic Report Email: {testResults.forensicReportEmail || 'Not specified'}</li>
                  <li>Raw DMARC Record: {testResults.rawDMARCRecord || 'Not specified'}</li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

};

export default MXLookup;