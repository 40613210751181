import { Certificate } from '@common/Certificate';
import React, { Fragment } from 'react';

enum PublicKeyEncoding {
  COMPRESSED = 0x02,
  UNCOMPRESSED = 0x04,
}

type EllipticCurveInfoProps = {
  certificate: Certificate;
};

const parsePublicKeyData = (pubkeyData: number[]) => {
  const encoding = pubkeyData[0];
  const startIndex = encoding === PublicKeyEncoding.UNCOMPRESSED ? 1 : 0;

  const x = pubkeyData.slice(startIndex, startIndex + 32);

  if (x.length !== 32) {
    throw new Error('Invalid public key data');
  }

  if (encoding === PublicKeyEncoding.UNCOMPRESSED) {
    const y = pubkeyData.slice(startIndex + 32, startIndex + 64);

    if (y.length !== 32) {
      throw new Error('Invalid public key data');
    }
    return { x, y };
  } else {
    const yParity = pubkeyData[pubkeyData.length - 1] & 0x01 ? 'odd' : 'even';
    return { x, yParity };
  }
};

const EllipticCurveInfo: React.FC<EllipticCurveInfoProps> = ({ certificate: { pubkey, nistCurve } }) => {
  if (nistCurve !== "P-256" || !pubkey?.data) {
    return <Fragment />;
  }

  const { x, y } = parsePublicKeyData(pubkey.data);

  return (
    <div className="bg-white p-4 shadow rounded-lg">
      <h3 className="text-lg font-semibold text-gray-900 mb-3">Elliptic Curve Information</h3>
      <p className="text-sm text-gray-600">
        <span className="font-medium text-gray-700">X Coordinate:</span> {parseInt(x.join(''), 16)}
      </p>
      {typeof y === 'string' ? (
        <p className="text-sm text-gray-600 mt-2">
          <span className="font-medium text-gray-700">Y Coordinate (parity):</span> {y}
        </p>
      ) : (
        <p className="text-sm text-gray-600 mt-2">
          <span className="font-medium text-gray-700">Y Coordinate:</span> {y && parseInt(y.join(''), 16)}
        </p>
      )}
    </div>
  );
};

export default EllipticCurveInfo;