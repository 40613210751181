import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

interface IPRecordProps {
  ip: string;
}

function IPRecord({ ip }: IPRecordProps): JSX.Element {
  return (
    <>
      <Link to={`/ptr/${ip}`} title={`PTR Lookup - Reverse DNS`} className="mr-1">
        {ip}
      </Link>
      <Menu as="div" className="relative inline-block">
        <Menu.Button className="p-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <FontAwesomeIcon icon={faEllipsisVertical} size="sm" />
        </Menu.Button>
        <Menu.Items className="origin-top-right absolute right-0 z-10 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <span className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/country/${ip}`}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } block px-4 py-2 text-sm text-gray-700`}
                  title={`Find the geolocation of ${ip}`}
                >
                  Location
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/asn/${ip}`}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } block px-4 py-2 text-sm text-gray-700`}
                  title={`${ip}`}
                >
                  ASN
                </Link>
              )}
            </Menu.Item>
          </span>
        </Menu.Items>
      </Menu>
    </>
  );
}

export default IPRecord;
