import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SignedIn, SignedOut, useClerk } from '@clerk/clerk-react';
import { clearUser } from '../../features/user/userSlice';

interface AuthProps {
  redirect: string;
}

function Auth({ redirect }: AuthProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signOut, openSignIn } = useClerk();

  const handleSignOut = async () => {
    await signOut();
    dispatch(clearUser());
    navigate('/');
  };
  const handleSignIn = async () => {
    await openSignIn({
      redirectUrl: redirect,
    });
  };

  return (
    <div>
      <SignedOut>
        <button className="px-3 py-2 rounded-md text-lg font-medium font-mono text-white hover:bg-green-600 text-nowrap" onClick={handleSignIn}>Sign In</button>
      </SignedOut>
      <SignedIn>
        <button className="px-3 py-2 rounded-md text-lg font-medium font-mono text-white hover:bg-red-500 text-nowrap" onClick={handleSignOut}>Sign Out</button>
      </SignedIn>
    </div>
  );
}

export default Auth;
