import React, { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setUser, clearUser } from './features/user/userSlice';
import { AppDispatch } from './features/store/store';
import CipherLookup from './components/CipherLookup';
import MXLookup from './components/MXLookup';
import AuthoritativeDNS from './components/AuthoritativeDNS';
import SPFLookup from './components/SPFLookup';
import BIMILookup from './components/BIMILookup';
import DKIMLookup from './components/DKIMLookup';
import ValidateDNS from './components/ValidateDNS';
import IPBanner from './components/IPBanner';
import PTRLookup from './components/PTRLookup';
import ResolveHostname from './components/ResolveHostname';
import ASNLookup from './components/ASNLookup';
import About from './components/About';
import CountryLookup from './components/CountryLookup';
import WhoisLookup from './components/WhoisLookup';
import ASNToIP from './components/ASNToIP';
import Footer from './components/Footer';
import BlacklistInfo from './components/BlacklistInfo';
import Experimental from './components/Experimental';
import Privacy from './components/Privacy';
import TOS from './components/TOS';
import Account from './components/Account';
import TCPPing from './components/TCPPing';
import MainForm from './components/MainForm';


function App() {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.toString()
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }
  }, [user, dispatch]);

  return (
    <div className="flex flex-col h-screen">
      <IPBanner />
      <main className="flex-1">
        <Routes>
          <Route path="/" element={<MainForm />} />
          <Route path="/cert/:hostname?" element={<CipherLookup />} />
          <Route path="/address/:hostname?" element={<ResolveHostname />} />
          <Route path="/mx-lookup/:hostname?" element={<MXLookup />} />
          <Route path="/dns/:domain?" element={<AuthoritativeDNS />} />
          <Route path="/spf/:hostname?" element={<SPFLookup />} />
          <Route path="/bimi/:hostname?" element={<BIMILookup />} />
          <Route path="/dkim/:domain?/:selector?" element={<DKIMLookup />} />
          <Route path="/validate-dns/:hostname?" element={<ValidateDNS />} />
          <Route path="/ptr/:ipAddress?" element={<PTRLookup />} />
          <Route path="/asn/:ipAddress?" element={<ASNLookup />} />
          <Route path="/blacklist/:ipAddress?" element={<BlacklistInfo />} />
          <Route path="/country/:ipAddress?" element={<CountryLookup />} />
          <Route path="/whois/:hostname?" element={<WhoisLookup />} />
          <Route path="/asntoip/:asn?" element={<ASNToIP />} />
          <Route path="/about" element={<About />} />
          <Route path="/experimental" element={<Experimental />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/account" element={<Account />} />
          <Route path="/tcpping/:host/:port/:retryCount/:interval" element={<TCPPing />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
