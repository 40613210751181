import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import QueryForm from '../QueryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface ASNLookupProps { }

const ASNLookup: React.FC<ASNLookupProps> = () => {
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  const { ipAddress } = useParams<{ ipAddress: string }>();
  const [asnResults, setAsnResults] = useState<Array<{ ipAddress: string; asn: number; organization: string }>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/GetASN', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddresses: [ipAddress] }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        setAsnResults(jsonResponse.asnResults);
      } catch (error) {
        console.error(error);
      }
    };

    if (ipAddress) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/asn/${ipAddress}`,
        });
        return;
      }
      fetchData();
    }
  }, [ipAddress, openSignIn, userInfo]);

  const pageTitle = ipAddress ? `ASN Lookup for ${ipAddress} - Find ASN Details Easily` : "Discover ASN Details from IP Address - Quick ASN Lookup Tool";
  const pageDescription = "Quickly find Autonomous System Numbers (ASN) associated with any IP address. Ideal for IT professionals, network administrators, and cyber security experts seeking detailed network information.";
  const keywords = "ASN Lookup, ASN Lookup Tool, network information";

  const helmet = () => (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={`https://netquery.tools/asn/${ipAddress || ''}`} />
    </Helmet>
  );

  if (!ipAddress) {
    return (
      <>
        {helmet()}
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faInfoCircle} className="text-blue-500" /> ASN Lookup Tool</h1>
            <QueryForm placeholder="Enter an IP address..." ariaLabel="Enter an IP address for ASN lookup" navigatePath={(inputValue) => `/asn/${inputValue}`} />
            <div className="mt-8">
              <div className="mb-4">
                <h2 className="text-xl font-semibold">What is an ASN Lookup?</h2>
                <p>ASN Lookup helps identify the Autonomous System Number associated with IP addresses, providing essential network information.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">How to perform an IP ASN Lookup?</h2>
                <p>Enter the IP address in the search bar and our tool will display the ASN details associated with it.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Can I find ASN details for IPv6 addresses?</h2>
                <p>Yes, our tool supports ASN lookup for both IPv4 and IPv6 addresses.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Why is ASN information important?</h2>
                <p>Understanding ASN information is crucial for network routing, security analysis, and managing IP address allocation, offering insights into the network's ownership and topology.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">What can I learn from an ASN Lookup?</h2>
                <p>An ASN Lookup can reveal the organization that owns the IP address range, traffic routing policies, and potentially the geographical location of IP addresses.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">How accurate are ASN Lookup results?</h2>
                <p>ASN Lookup results are highly accurate, relying on up-to-date databases and records from regional internet registries (RIRs) and other authoritative sources.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Can ASN Lookup help with cyber security?</h2>
                <p>Yes, ASN Lookup is a valuable tool for cyber security professionals to trace malicious activities, identify network vulnerabilities, and implement more effective security measures.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {helmet()}
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">ASN Lookup for {ipAddress}</h2>
        {asnResults.map((result, index) => (
          <div key={index} className="mb-4 p-4 border rounded-lg">
            <div className="mb-2">
              <label htmlFor="ip-address" className="block text-gray-700 font-medium mb-1">
                IP Address:
              </label>
              <span className="px-3 py-1 bg-gray-200 rounded text-gray-700">{result.ipAddress}</span>
            </div>
            <div className="mb-2">
              <label htmlFor="asn" className="block text-gray-700 font-medium mb-1">
                ASN:
              </label>
              <span className="px-3 py-1 bg-gray-200 rounded text-gray-700">
                <a href={`/asntoip/${result.asn}`} className="text-blue-500 hover:text-blue-700 underline">
                  {result.asn}
                </a>
              </span>
            </div>
            <div className="mb-2">
              <label htmlFor="organization" className="block text-gray-700 font-medium mb-1">
                Organization:
              </label>
              <span className="px-3 py-1 bg-gray-200 rounded text-gray-700">{result.organization}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ASNLookup;