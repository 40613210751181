import React from 'react';
import { RedirectToSignIn, RedirectToUserProfile, SignedIn, SignedOut } from '@clerk/clerk-react';
const About: React.FC = () => {
  return (
    <div className="about-content">
      <SignedIn>
        <RedirectToUserProfile/>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn/>
      </SignedOut>
    </div>
  );
};

export default About;
