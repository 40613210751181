import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CipherStatusList } from '../../types/CipherStatus';

interface CipherTableProps {
  data: CipherStatusList;
}

const CipherTable = ({ data }: CipherTableProps) => {
  const [showOnlyConnected, setShowOnlyConnected] = useState(true);

  const filteredData = showOnlyConnected
    ? data.filter((item) => item.connected)
    : data;

  const getRowColor = (strength: string) => {
    switch (strength) {
      case 'Strong':
        return 'text-green-600';
      case 'Moderate':
        return 'text-orange-500';
      case 'Weak':
        return 'text-red-600';
      default:
        return 'text-black';
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <label className="mr-2">
          Show only connected:
        </label>
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5"
          checked={showOnlyConnected}
          onChange={(e) => setShowOnlyConnected(e.target.checked)}
        />
      </div>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2 text-left">Cipher Suite</th>
            {!showOnlyConnected && <th className="border px-4 py-2">Connected</th>}
            <th className="border px-4 py-2">Strength</th>
            <th className="border px-4 py-2">Protocol</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length === 0 ? (
            <tr>
              <td colSpan={showOnlyConnected ? 3 : 4} className="border px-4 py-2 text-center">
                No items to display
              </td>
            </tr>
          ) : (
            filteredData.map((item) => (
              <tr key={item.name}>
                <td className="border px-4 py-2 mono">
                  {item.name.toUpperCase()} ({item.hexCode}) {item.supportsPFS ? "PFS" : ""}
                </td>
                {!showOnlyConnected && (
                  <td className={`border px-4 py-2 ${item.connected ? 'text-green-600' : 'text-red-600'}`}>
                    {item.connected ? 'Yes' : 'No'}
                  </td>
                )}
                <td className={`border px-4 py-2 ${getRowColor(item.strength)}`}>
                  {item.strength}
                </td>
                <td className="border px-4 py-2">
                  {/^(tls_aes_|tls_chacha20_)/i.test(item.name) ? "TLSv1.3" : "TLSv1.2"}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

CipherTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      hexCode: PropTypes.string.isRequired,
      supportsPFS: PropTypes.bool.isRequired,
      connected: PropTypes.bool.isRequired,
      strength: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CipherTable;
