import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LookupTypeState {
  lookupType: string;
}

const initialState: LookupTypeState = {
  lookupType: '',
};

const lookupTypeSlice = createSlice({
  name: 'lookupType',
  initialState,
  reducers: {
    setLookupType: (state, action: PayloadAction<string>) => {
      state.lookupType = action.payload;
    },
    resetLookupType: (state) => {
      state.lookupType = '';
    },
  },
});

export const { setLookupType, resetLookupType } = lookupTypeSlice.actions;
export default lookupTypeSlice.reducer;