import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HostRecord from '../HostRecord';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import QueryForm from '../QueryForm';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface PTRLookupProps { }

const PTRLookup: React.FC<PTRLookupProps> = () => {
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  const { ipAddress } = useParams<{ ipAddress: string }>();
  const [hostname, setHostname] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/ResolvePTRRecord', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddress }),
        });

        const jsonResponse = await response.json();
        if (response.ok) {
          if (jsonResponse.status === 'info') {
            setMessage(jsonResponse.message);
          } else {
            const hostname: string = jsonResponse.hostname;
            setHostname(hostname);
          }
        } else {
          setStatus('error');
          setMessage(`Request failed with status ${response.status}`);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (ipAddress) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/ptr/${ipAddress}`,
        });
        return;
      }
      fetchData();
    }
  }, [ipAddress,openSignIn,userInfo]);

  const pageTitle = ipAddress ? `PTR Lookup for ${ipAddress} - Reverse DNS Lookup Tool` : "Reverse DNS Lookup Tool: Discover Domain Names from IP Addresses";
  const pageDescription = "Use our Reverse DNS Lookup Tool to quickly find domain names associated with any IP address. Essential for IT professionals and network administrators, our tool helps validate reverse DNS setups, ensuring email deliverability and network integrity.";

  const helmet = () => {
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content="Reverse DNS Lookup, PTR Record Check, IP to Domain Name, Reverse DNS Configuration, Email Server Reverse DNS, IPv6 Reverse Lookup, DNS Reverse Query, Network Tools, Reverse DNS for Office 365, Reverse DNS Validation, Reverse IP Lookup" />
      <link rel="canonical" href={`https://netquery.tools/ptr/${ipAddress ? ipAddress : ''}`} />
    </Helmet>
  }

  if (!ipAddress) {
    return (
      <>
        {helmet()}
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faSyncAlt} className="text-blue-500" /> Reverse DNS Lookup Tool</h1>
            <QueryForm placeholder="Enter an IP address..." ariaLabel="Enter an IP address for PTR lookup" navigatePath={(inputValue) => `/ptr/${inputValue}`} />
            <div className="mt-8">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">Frequently Asked Questions</h2>
              <div className="text-gray-600">
                <p><strong>What is Reverse DNS?</strong> Reverse DNS (rDNS) is the process of determining the domain name associated with an IP address. It's crucial for verifying the legitimacy of the server in network communications and email delivery.</p>
                <p><strong>Why is Reverse DNS Important for Email?</strong> Email servers perform reverse DNS lookups to verify the sending server's domain name against its IP address, reducing spam by ensuring the sender is legitimate.</p>
                <p><strong>Can Reverse DNS be Used for IPv6 Addresses?</strong> Yes, reverse DNS works with IPv6 addresses. The lookup process is similar but adapts to the IPv6 address structure, helping in accurately mapping IP addresses to domain names.</p>
                <p><strong>How to Change Reverse DNS Settings?</strong> Changing reverse DNS settings typically requires access to the DNS management interface provided by your hosting service or internet service provider, where you can update PTR records.</p>
                <p><strong>What is a Reverse DNS Record?</strong> A reverse DNS record, or PTR record, maps an IP address back to a domain name. It's used in reverse DNS lookups to find the domain name associated with an IP address.</p>
                <p><strong>How Does Reverse DNS Affect Security?</strong> Reverse DNS can enhance security by allowing network services to verify the domain names of the servers they communicate with, helping prevent man-in-the-middle attacks and ensuring data integrity.</p>
                <p><strong>Is Reverse DNS Necessary for All Servers?</strong> While not strictly necessary for all servers, reverse DNS is highly recommended for mail servers and any server that communicates with external services to ensure reliability and trustworthiness.</p>
                <p><strong>What Happens if Reverse DNS Fails?</strong> If reverse DNS lookup fails, it can lead to email delivery issues, with emails being flagged as spam or outright rejected by email servers, and can also impact the trustworthiness of the server in other network communications.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


  return (
    <>
      {helmet()}
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">PTR Record Lookup</h2>
        <div className="mb-4">
          <label htmlFor="ip-address" className="block text-gray-700 text-sm font-bold mb-2">IP Address:</label>
          <span className="px-4 py-2 border rounded text-gray-700">{ipAddress}</span>
        </div>
        <div className="mb-4">
          <label htmlFor="hostname" className="block text-gray-700 text-sm font-bold mb-2">Hostname:</label>
          <span className="px-4 py-2 border rounded text-gray-700">
            {hostname ? (
              <HostRecord host={hostname} />
            ) : (
              <span className="text-gray-500">{message}</span>
            )}
          </span>
        </div>
        {status === 'error' && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{message}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default PTRLookup;