import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FlagIcon from '../FlagIcon';
import { Helmet } from 'react-helmet';
import QueryForm from '../QueryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface CountryLookupProps { }

const CountryLookup: React.FC<CountryLookupProps> = () => {
  const { ipAddress } = useParams<{ ipAddress: string }>();
  const [countryResults, setCountryResults] = useState<Array<{ ipAddress: string; countryCode: string; countryName: string }>>([]);
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/GetCountry', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddresses: [ipAddress] }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        setCountryResults(jsonResponse.countryResults);
      } catch (error) {
        console.error(error);
      }
    };
    if (ipAddress) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/country/${ipAddress}`,
        });
        return;
      }
      fetchData();
    }
  }, [ipAddress, userInfo, openSignIn]);

  const pageTitle = ipAddress ? `IP to Country Lookup for ${ipAddress} - Find Location Details` : "IP to Country Lookup Tool - Discover IP Location Easily";
  const pageDescription = "Use our IP to Country Lookup tool to find the geographic location associated with any IP address. Get accurate country details quickly and easily.";
  const keywords = "IP to Country, IP Location Lookup, Find IP Country, Geographic IP Lookup, IP Address Location";

  const helmet = () => (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={`https://netquery.tools/country/${ipAddress || ''}`} />
    </Helmet>
  );

  if (!ipAddress) {
    return (
      <>
        {helmet()}
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faGlobeAmericas} className="text-blue-500" /> Discover IP Location</h1>
            <QueryForm placeholder="Enter an IP address..." ariaLabel="Enter an IP address for country lookup" navigatePath={(inputValue) => `/country/${inputValue}`} />
            <div className="mt-8">
              <div className="mb-4">
                <h2 className="text-xl font-semibold">What is IP to Country Lookup?</h2>
                <p>IP to Country Lookup is a tool that allows you to identify the geographic location of an IP address, specifically the country it is associated with.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">How accurate is IP to Country information?</h2>
                <p>The accuracy of IP to Country Lookup results can vary, but generally, it is quite reliable for determining the country of an IP address.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Can this tool identify the exact location within a country?</h2>
                <p>While IP to Country Lookup provides the country associated with an IP address, it may not always provide specific details like city or exact location due to IP address allocation and privacy concerns.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Is the IP to Country Lookup tool free to use?</h2>
                <p>Yes, our IP to Country Lookup tool is completely free to use for identifying the country of any IP address.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">How can this tool be used for online security?</h2>
                <p>Identifying the geographic location of IP addresses can help in assessing potential online threats, managing geo-blocking settings, and understanding global traffic patterns for security analysis.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {helmet()}
      <div className="container mx-auto p-4 bg-white shadow rounded-lg">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">Country Lookup</h2>
        {countryResults.map((result, index) => (
          <div key={index} className="mb-4 p-4 border-b last:border-b-0">
            <div className="flex items-center mb-2">
              <label htmlFor="ip-address" className="inline-block w-32 font-medium text-gray-600">
                IP Address:
              </label>
              <span className="font-semibold text-gray-800">{result.ipAddress}</span>
            </div>
            <div className="flex items-center mb-2">
              <label htmlFor="country-code" className="inline-block w-32 font-medium text-gray-600">
                Country Code:
              </label>
              <span className="font-semibold text-gray-800">{result.countryCode}</span>
            </div>
            <div className="flex items-center">
              <label htmlFor="country-name" className="inline-block w-32 font-medium text-gray-600">
                Country Name:
              </label>
              <FlagIcon countryCode={result.countryCode} className="w-5 h-5 mr-2" />
              <span className="font-semibold text-gray-800">{result.countryName}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CountryLookup;
