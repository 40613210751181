import React from 'react';
import { Link } from 'react-router-dom';

interface HostRecordProps {
    host: string
}

function HostRecord({ host }: HostRecordProps): JSX.Element {
    return (
        <Link to={`/address/${host}`} title={`${host} - Lookup IP addresses`}>
            {host}
        </Link>
    );
}

export default HostRecord;
