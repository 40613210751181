export function isIPInCIDR(ip: string, cidr: string): boolean {
  const [range, prefixLength] = cidr.split("/");
  const prefixBits = parseInt(prefixLength, 10);

  function matchBits(a: number[], b: number[], bits: number): boolean {
    const maxBits = a.length * 8;
    let matchedBits = 0;

    for (let i = 0; i < maxBits; i++) {
      if (matchedBits >= bits) break;

      const byteIndex = Math.floor(i / 8);
      const bitIndex = 7 - (i % 8);

      const bitA = (a[byteIndex] >> bitIndex) & 1;
      const bitB = (b[byteIndex] >> bitIndex) & 1;

      if (bitA === bitB) {
        matchedBits++;
      } else {
        break;
      }
    }

    return matchedBits === bits;
  }

  if (ip.indexOf(".") !== -1) {
    // IPv4
    const ipBytes = ip.split(".").map(Number);
    const rangeBytes = range.split(".").map(Number);
    return matchBits(ipBytes, rangeBytes, prefixBits);
  } else {
    // IPv6
    const ipBytes = ip
      .split(":")
      .join("")
      .match(/.{1,2}/g)!
      .map((hex) => parseInt(hex, 16));
    const rangeBytes = range
      .split(":")
      .join("")
      .match(/.{1,2}/g)!
      .map((hex) => parseInt(hex, 16));
    return matchBits(ipBytes, rangeBytes, prefixBits);
  }
}

export function explainMechanism(mechanism: string): string {
  const qualifier = mechanism.charAt(0);

  const explanationForAll = (qualifier: string) => {
    switch (qualifier) {
      case "+":
        return "Pass: Allow all addresses.";
      case "-":
        return "Fail: Deny all addresses.";
      case "~":
        return "SoftFail: Allow but mark all addresses.";
      case "?":
        return "Neutral: No policy for all addresses.";
      default:
        return "Unknown qualifier.";
    }
  };

  switch (mechanism.split(":")[0]) {
    case "a":
      return `Match if the domain name has an address record (A or AAAA) that can be resolved${
        mechanism.includes(":") ? " for " + mechanism.split(":")[1] : ""
      }.`;
    case "mx":
      return `Match if the domain has a mail exchange (MX) record that can be resolved${
        mechanism.includes(":") ? " for " + mechanism.split(":")[1] : ""
      }.`;
    case "ip4":
      return `Match if the client IP address falls within the specified IPv4 range (${
        mechanism.split(":")[1]
      }).`;
    case "ip6":
      return `Match if the client IP address falls within the specified IPv6 range (${
        mechanism.split(":")[1]
      }).`;
    case "include":
      return `Include the SPF policy of another domain (${
        mechanism.split(":")[1]
      }).`;
    case "?all":
    case "+all":
    case "-all":
    case "~all":
    case "all":
      return explanationForAll(qualifier);
    default:
      return "Unknown mechanism.";
  }
}

export interface ParsedSPF {
  version: string;
  mechanisms: string[];
  qualifiers: string[];
}

export function parseSPFRecord(record: string): ParsedSPF {
  const parts = record.split(" ");

  const version = parts.shift();
  if (version !== "v=spf1") {
    throw new Error("Invalid SPF version");
  }

  const mechanisms: string[] = [];
  const qualifiers: string[] = [];

  for (const part of parts) {
    const qualifier = part.charAt(0);
    if (["-", "~", "+", "?"].includes(qualifier)) {
      qualifiers.push(qualifier);
      mechanisms.push(part);
    } else {
      qualifiers.push("+");
      mechanisms.push(part);
    }
  }

  return { version, mechanisms, qualifiers };
}

async function handleFetchErrors(response: Response) {
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch MX records: ${errorText}`);
  }
  return response;
}

export async function getMXRecords(domain: string) {
  try {
    const response = await fetch("/api/GetMXRecords", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ host: domain }),
    })
      .then(handleFetchErrors)
      .then((response) => response.json());

    return response.mxRecords;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error("An unknown error occurred while fetching MX records.");
    }
    return [];
  }
}

export async function resolveHostname(hostname: string) {
  try {
    const response = await fetch("/api/ResolveHostname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ host: hostname }),
    })
      .then(handleFetchErrors)
      .then((response) => response.json());

    const { ARecords, AAAARecords } = response;
    return { ARecords, AAAARecords };
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error resolving hostname:", error.message);
    } else {
      console.error("An unknown error occurred while resolving the hostname.");
    }
    throw error;
  }
}

export async function resolvePtrRecord(ip: string): Promise<string | null> {
  try {
    const response = await fetch('/api/ResolvePTRRecord', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ipAddress: ip }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.hostname;
    } else {
      throw new Error('Failed to resolve PTR record');
    }
  } catch (error) {
    console.error('Failed to fetch PTR record:', error);
    return null;
  }
}

export async function fetchSPFRecord(host: string, expandAll: boolean = false): Promise<SPFResponse> {
  const response = await fetch('/api/GetSPF', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ host, recursive: expandAll }),
  });

  const data: SPFResponse = await response.json();
  return data;
}

export interface SPFResponse {
  SPFRecords: string[];
}

export async function testIPAddressInSPF(spf: ParsedSPF, ip: string, lookupCount: number): Promise<{ result: string; explanation: string }> {
  const mechanisms = spf.mechanisms;

  for (const mechanism of mechanisms) {
    console.log("Testing mechanism:", mechanism);

    const prefix = mechanism.split(':')[0];

    switch (prefix) {
      case 'ip4':
      case 'ip6':
        const cidr = mechanism.slice(4);
        if (isIPInCIDR(ip, cidr)) {
          return {
            result: "PASS",
            explanation: "The IP address is explicitly allowed by the SPF record.",
          };
        }
        break;
      case 'a':
        const aDomain = mechanism.slice(2);
        const { ARecords, AAAARecords } = await resolveHostname(aDomain);
        const aAddresses = [...ARecords.map((record: { address: string; ttl: number }) => record.address), ...AAAARecords.map((record: { address: string; ttl: number }) => record.address)];
        console.log("Addresses:", aAddresses);
        if (aAddresses.includes(ip)) {
          return {
            result: "PASS",
            explanation: "The IP address is allowed by the SPF record (mechanism 'a:').",
          };
        }
        lookupCount++;
        break;
      case 'include':
        const includeDomain = mechanism.slice(8);
        const includeData = await fetchSPFRecord(includeDomain);

        console.log("SPFResponse:", includeData);
        if (includeData && Array.isArray(includeData.SPFRecords) && includeData.SPFRecords.length > 0) {
          const includeSPF = parseSPFRecord(includeData.SPFRecords[0]);
          const includeResult = await testIPAddressInSPF(includeSPF, ip, lookupCount);
          if (includeResult.result === "PASS") {
            return {
              result: "PASS",
              explanation: `The IP address is allowed by the SPF record (mechanism 'include:' with domain ${includeDomain}).`,
            };
          }
        }
        lookupCount++;
        break;
      case 'mx':
        const domain = mechanism.slice(3);
        const mxRecords = await getMXRecords(domain);
        const addresses = [];

        for (const mxRecord of mxRecords) {
          const { ARecords, AAAARecords } = await resolveHostname(mxRecord.exchange);
          addresses.push(
            ...ARecords.map((record: { address: string; ttl: number }) => record.address),
            ...AAAARecords.map((record: { address: string; ttl: number }) => record.address)
          );
        }

        console.log("MX Addresses:", addresses);

        if (addresses.includes(ip)) {
          return {
            result: "PASS",
            explanation: "The IP address is allowed by the SPF record (mechanism 'mx:').",
          };
        }

        lookupCount++;
        break;
      case 'ptr':
        return {
          result: "UNKNOWN",
          explanation: "This tool does not currently support ptr:"
        };
      case 'exists':
        return {
          result: "UNKNOWN",
          explanation: "This tool does not currently support exists:"
        };
    }

  }
  console.log("mechanisms: ", mechanisms)
  if (mechanisms.includes("?all")) {
    return {
      result: "NEUTRAL",
      explanation: "The SPF record specifies a neutral result for all IPs not explicitly allowed (mechanism '?all').",
    };
  } else if (mechanisms.includes("~all")) {
    return {
      result: "SOFTFAIL",
      explanation: "The SPF record specifies a soft fail for all IPs not explicitly allowed (mechanism '~all').",
    };
  } else if (mechanisms.includes("-all") || mechanisms.includes("all")) {
    return {
      result: "FAIL",
      explanation: "The IP address is not allowed by the SPF record (mechanism '-all' or 'all').",
    };
  }


  return {
    result: "UNKNOWN",
    explanation: "Unable to determine the result for the given IP address.",
  };
}
