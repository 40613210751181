// components/ValidateDNS.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HostRecord from '../HostRecord';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import QueryForm from '../QueryForm';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface ValidateDNSRecordsProps { }

interface DNSTestResult {
  category: string;
  host: string;
  result: string;
  details?: {
    glueRecords?: string[];
    serialNumbers?: number[];
  };
}

const ValidateDNSRecords: React.FC<ValidateDNSRecordsProps> = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const [dnsTestResults, setDnsTestResults] = useState<DNSTestResult[]>([]);
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/ValidateDNSRecords', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ hostname }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        const testResults: DNSTestResult[] = jsonResponse.dnsTestsResults;
        setDnsTestResults(testResults);
      } catch (error) {
        console.error(error);
      }
    };
    if (hostname) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/validate-dns/${hostname}`,
        });
        return;
      }
      fetchData();
    }
  }, [hostname,openSignIn,userInfo]);

  const pageTitle = hostname ? `DNS Verifier for ${hostname}` : "DNS Verifier: Ensure Your Domain's DNS Integrity";
  const pageDescription = "DNS Verifier helps validate DNS configurations, ensuring glue records and SOA records are correctly set up for your domain. Avoid DNS resolution issues and enhance domain reliability with our tool.";

  const helmet = () => {
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content="DNS Verifier, Validate DNS, DNS Glue Record Check, SOA Record Validation, Domain DNS Configuration, DNS Integrity Check, DNS Setup Verification" />
      <link rel="canonical" href={`https://netquery.tools/validate-dns/${hostname ? hostname : ''}`} />
    </Helmet>
  }

  if (!hostname) {
    return (
      <>
        {helmet()}
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faKey} className="text-blue-500" /> DNS Configuration Verifier</h1>
            <QueryForm placeholder="Enter a domain to check its DNS configuration..." ariaLabel="Enter a domain for DNS integrity check" navigatePath={(inputValue) => `/validate-dns/${inputValue}`} />
            <p className="pt-4">DNS Verifier is an essential tool for webmasters and IT professionals to ensure the DNS records of a domain are properly configured. It checks for glue record accuracy and SOA record consistency, preventing common DNS issues that affect domain accessibility and performance.</p>
          </div>
        </div>
        <div className="max-w-4xl mx-auto mt-8">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Frequently Asked Questions (FAQ)</h2>
          <dl>
            <dt className="text-xl font-semibold text-gray-800 mt-4">What are DNS glue records?</dt>
            <dd className="text-md text-gray-600">DNS glue records are essential for resolving circular dependencies in DNS queries, linking domain names to IP addresses for name servers within the same domain.</dd>
            <dt className="text-xl font-semibold text-gray-800 mt-4">How can I check DNS glue records?</dt>
            <dd className="text-md text-gray-600">Use DNS Verifier to automatically check and validate the accuracy of DNS glue records for your domain, ensuring they are correctly pointing to the right IP addresses.</dd>
            <dt className="text-xl font-semibold text-gray-800 mt-4">Why is SOA record consistency important?</dt>
            <dd className="text-md text-gray-600">SOA record consistency across name servers is crucial for the correct propagation of DNS information, ensuring all users reach the same state of your website without encountering DNS errors.</dd>
          </dl>
        </div>
      </>
    )
  }

  return (
    <>
      {helmet()}
      <div className="max-w-4xl mx-auto mt-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">DNS Validation Results for {hostname}</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full h-16 border-gray-300 border-b py-8">
                <th className="text-left pl-8 pr-6 text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Category
                </th>
                <th className="text-left pl-6 pr-6 text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Host
                </th>
                <th className="text-left pl-6 pr-6 text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Result
                </th>
                <th className="text-left pl-6 pr-6 text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Glue Records
                </th>
                <th className="text-left pl-6 pr-6 text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Serial Numbers
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {dnsTestResults.map((testResult, index) => (
                <tr key={index} className="h-12 border-gray-300 border-b">
                  <td className="pl-8 pr-6 whitespace-no-wrap">{testResult.category}</td>
                  <td className="pl-6 pr-6 whitespace-no-wrap"><HostRecord host={testResult.host} /></td>
                  <td className="pl-6 pr-6 whitespace-no-wrap">{testResult.result}</td>
                  <td className="pl-6 pr-6 whitespace-no-wrap">{testResult.details?.glueRecords?.join(', ') || 'N/A'}</td>
                  <td className="pl-6 pr-6 whitespace-no-wrap">{testResult.details?.serialNumbers?.join(', ') || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ValidateDNSRecords;