import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QueryForm from '../QueryForm';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

interface BlacklistResult {
  blacklist: string;
  isListed: boolean;
  errorCode?: string;
  listingType?: string;
}

interface BlacklistCheckResult {
  ip: string;
  results: BlacklistResult[];
}

interface BlacklistInfoProps { }

const BlacklistInfo: React.FC<BlacklistInfoProps> = () => {
  const { ipAddress } = useParams<{ ipAddress: string }>();
  const [blacklistResults, setBlacklistResults] = useState<BlacklistCheckResult[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/GetBlacklistInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddresses: [ipAddress] }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        setBlacklistResults(jsonResponse.blacklistResults);
      } catch (error) {
        console.error(error);
      }
    };
    if (ipAddress) {
      fetchData();
    }
  }, [ipAddress]);

  const renderResult = (blacklistResult: BlacklistResult) => {
    const listedClass = blacklistResult.isListed ? 'text-red-600' : 'text-green-600';
    if (blacklistResult.errorCode) {
      return <span className="text-red-600">Error: {blacklistResult.errorCode}</span>;
    }
    if (blacklistResult.isListed) {
      return <span className={listedClass}>Listed - {blacklistResult.listingType}</span>;
    }
    return <span className={listedClass}>Not Listed</span>;
  };

  const pageTitle = ipAddress ? `Blacklist check for ${ipAddress} - IP Blacklist Check Tool - Safeguard Your Network | NetQuery Tools` : "IP Blacklist Check Tool - Safeguard Your Network | NetQuery Tools";
  const pageDescription = "Effortlessly identify if your IP is blacklisted with NetQuery Tools. Secure your network integrity by utilizing our comprehensive IP blacklist check tool";


  if (!ipAddress) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content="IP Blacklist Check, Real-time Blacklist Verification, IP Reputation, Spamhaus Check, Google Blacklist, Bulk IP Check, IP Address Monitoring, Network Security Tools, IP Blacklist Removal, Blacklist Lookup, IP Whitelist" />
          <link rel="canonical" href={`https://netquery.tools/blacklist${ipAddress ? `/${ipAddress}` : ''}`} />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faBan} className="text-red-500" /> Blacklist IP Check</h1>
            <p className="mb-4">Discover instantly if your IP address is blacklisted across major blacklist databases. Ensure the integrity of your network and maintain your online reputation with our easy-to-use IP blacklist check tool. Perfect for IT professionals, marketers, and webmasters to prevent email delivery issues and enhance server security.</p>
            <QueryForm placeholder="Enter an IP address..." ariaLabel="Enter an IP address for a blacklist check" navigatePath={(inputValue) => `/blacklist/${inputValue}`} />
            <h2 className="text-3xl font-bold text-gray-800 mt-8 mb-4">Why Check IP Blacklist?</h2>
            <p className="mb-4">Being listed on an IP blacklist can significantly affect your email deliverability, website accessibility, and overall network reputation. Regular checks help you stay ahead of potential issues, ensuring seamless communication and website uptime.</p>
            <h2 className="text-3xl font-bold text-gray-800 mt-8 mb-4">Features</h2>
            <ul className="list-disc pl-5 mb-4">
              <li>Real-time blacklist checks from leading databases.</li>
              <li>Detailed reports on blacklist status, including reasons for blacklisting and removal advice.</li>
            </ul>
            <h2 className="text-3xl font-bold text-gray-800 mt-8 mb-4">Use Cases</h2>
            <p className="mb-4">Ideal for managing the reputation of mail servers, preventing email spam, securing web servers, and monitoring IP addresses for hosting services. A must-have tool for maintaining a healthy network and improving cybersecurity posture.</p>
          </div>
        </div>
      </>
    )

  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="MX Lookup, DNS Lookup, Email Domain Check, Mail Domain Check, MX Records, Email Routing, Domain MX Check, Find MX Records" />
        <link rel="canonical" href="https://netquery.tools/blacklist" />
      </Helmet>
      <div className="container mx-auto p-4 bg-white shadow-lg rounded-md">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Blacklist Information for {ipAddress}</h2>
        {blacklistResults.map((result, index) => (
          <div key={index} className="mb-6 p-4 border-b last:border-b-0">
            <div className="mb-2">
              <label htmlFor="ip-address" className="block text-sm font-medium text-gray-700">
                IP Address:
              </label>
              <span className="text-lg font-semibold text-gray-800">{result.ip}</span>
            </div>
            {result.results.map((blacklistResult, idx) => (
              <div key={idx} className="mb-2">
                <label htmlFor={`blacklist-${idx}`} className="block text-sm font-medium text-gray-700">
                  {blacklistResult.blacklist}:
                </label>
                <span className="text-lg font-semibold">
                  {renderResult(blacklistResult)}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default BlacklistInfo;
