import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { explainMechanism, fetchSPFRecord, ParsedSPF, parseSPFRecord, testIPAddressInSPF } from './utils';
import { Helmet } from 'react-helmet';
import QueryForm from '../QueryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface SPFRecords {
  records: string[];
}

const SPFLookup: React.FC = () => {
  const { hostname } = useParams();
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  const [spfRecord, setSPFRecord] = useState<SPFRecords | null>(null);
  const [parsedSPF, setParsedSPF] = useState<ParsedSPF | null>(null);
  const [loading, setLoading] = useState(true);
  const [ipAddress, setIPAddress] = useState('');
  const [ipTestResult, setIPTestResult] = useState<{ result: string; explanation: string } | null>(null);
  const [ptrWarning, setPtrWarning] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (parsedSPF) {
      setPtrWarning(parsedSPF.mechanisms.some((mechanism) => mechanism.startsWith("ptr:")));
    }
  }, [parsedSPF]);

  useEffect(() => {
    async function fetchAndSetSPFRecord() {
      try {
        if (hostname) {
          const data = await fetchSPFRecord(hostname, expandAll);
          if (data && Array.isArray(data.SPFRecords) && data.SPFRecords.length > 0) {
            setSPFRecord({ records: data.SPFRecords });
            setParsedSPF(parseSPFRecord(data.SPFRecords[0]));
          } else {
            console.error('Received data is not in the expected format:', data);
          }
        }
      } catch (error) {
        console.error('Failed to fetch SPF record:', error);
      } finally {
        setLoading(false);
      }
    }
    if (hostname) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/spf/${hostname}`,
        });
        return;
      }
      fetchAndSetSPFRecord();
    }
  }, [hostname, expandAll, openSignIn, userInfo]);

  const handleTestButtonClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();
    await testIPAddress();
  };

  async function testIPAddress(lookupCount = 0) {
    if (!parsedSPF || !ipAddress || lookupCount >= 10) {
      return;
    }
    const result = await testIPAddressInSPF(parsedSPF, ipAddress.trim(), lookupCount);
    setIPTestResult(result);
  }

  const pageTitle = hostname ? `Check SPF Record for ${hostname} - Quick SPF Lookup | NetQuery Tools` : "SPF Record Checker & Validator | NetQuery Tools";
  const pageDescription = "Easily check, validate, and troubleshoot your SPF records with NetQuery Tools. Ensure your email security is up to par.";

  if (!hostname) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content="SPF record check, SPF record lookup, SPF validator, email security, SPF record example, SPF record syntax, domain security, prevent email spoofing, SPF record generator, SPF record analyzer" />
          <link rel="canonical" href={`https://netquery.tools/spf/${hostname ? hostname : ''}`} />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faEnvelopeOpenText} className="text-blue-500" /> SPF Lookup & Validation Tool</h1>
            <p className="mb-4">Quickly find and fix issues with your SPF records to improve email deliverability.</p>
            <QueryForm placeholder="Enter a domain name..." ariaLabel="Enter a domain name for SPF record check" navigatePath={(inputValue) => `/spf/${inputValue}`} />
            <div className="mt-8">
              <h2 className="text-2xl font-semibold mb-2">FAQs About SPF Records</h2>
              <dl className="space-y-4">
                <div>
                  <dt className="font-medium">What is an SPF record?</dt>
                  <dd>- An SPF (Sender Policy Framework) record is a DNS text entry that helps prevent email spoofing by specifying which mail servers are permitted to send email on behalf of your domain.</dd>
                </div>
                <div>
                  <dt className="font-medium">Why is an SPF record important?</dt>
                  <dd>- SPF records improve the security of your domain by preventing unauthorized use of your domain for email. They can also enhance your email deliverability by reducing the chance of your emails being marked as spam.</dd>
                </div>
                <div>
                  <dt className="font-medium">How can I check my SPF record?</dt>
                  <dd>- Use our SPF Record Checker tool by entering your domain name. It will retrieve and analyze your SPF record, highlighting any issues or recommendations for improvement.</dd>
                </div>
                <div>
                  <dt className="font-medium">What should an SPF record look like?</dt>
                  <dd>- A typical SPF record looks like: v=spf1 include:_spf.google.com ~all. This example authorizes emails from Google's servers and suggests a soft fail for other sources.</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="SPF record check, SPF record lookup, SPF validator, email security, SPF record example, SPF record syntax, domain security, prevent email spoofing, SPF record generator, SPF record analyzer" />
        <link rel="canonical" href={`https://netquery.tools/spf/${hostname ? hostname : ''}`} />
      </Helmet>
      <div className="bg-white p-8 rounded-md shadow max-w-4xl m-auto mt-8 mb-8">
        <h2 className="text-4xl font-semibold mb-4">SPF Record for {hostname}</h2>
        {loading ? (
          <p className="text-gray-800">Loading...</p>
        ) : (
          <>
            {spfRecord ? (
              <>
                <p>
                  <strong>Raw SPF Records:</strong>
                  <ul className="font-mono">
                    {spfRecord?.records?.map((record, index) => (
                      <li key={index}>{record}</li>
                    ))}
                  </ul>
                </p>
                <p>
                  <strong>Version:</strong> <span className="font-mono">{parsedSPF?.version}</span>
                </p>
                <p>
                  <strong>Mechanisms:</strong>
                  <ul className="pl-6 font-mono">
                    {parsedSPF?.mechanisms.map((mechanism, index) => (
                      <li key={index}>
                        {mechanism}: {explainMechanism(mechanism)}
                      </li>
                    ))}
                  </ul>
                </p>
                <p>
                  <strong>Qualifiers:</strong> <span className="font-mono">{parsedSPF?.qualifiers.join(', ')}</span>
                </p>
              </>
            ) : (
              <p>No SPF record found.</p>
            )}
            {parsedSPF && (
              <>
                <h3 className="text-2xl font-semibold mt-8 mb-2">Test IP Address</h3>
                <div className="input-container">
                  <label htmlFor="ip-address">Enter IP address:</label>
                  <input
                    type="text"
                    id="ip-address"
                    placeholder="Enter IP address"
                    value={ipAddress}
                    onChange={(e) => setIPAddress(e.target.value)}
                    className="input"
                  />
                </div>
                <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" onClick={handleTestButtonClick}>
                  Test
                </button>
                <div className="expand-all-container">
                  <label htmlFor="expand-all">Expand entire record:</label>
                  <input
                    type="checkbox"
                    id="expand-all"
                    checked={expandAll}
                    onChange={(e) => setExpandAll(e.target.checked)}
                  />
                </div>
                {ipTestResult && (
                  <>
                    <p className="mt-4 font-bold">
                      Result: <strong>{ipTestResult.result}</strong>
                    </p>
                    <p>{ipTestResult.explanation}</p>
                  </>
                )}
                {ptrWarning && (
                  <p className="ptr-warning">
                    Warning: The SPF record contains the deprecated 'ptr' mechanism.
                  </p>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SPFLookup;
