import { createSlice } from '@reduxjs/toolkit';

interface ClientPrincipal {
  userId: string;
  identityProvider: string;
  userDetails: string;
  claims: {
    [key: string]: string[];
  };
}

interface UserInfoState {
  data: ClientPrincipal | null;
}

const initialState: UserInfoState = {
  data: null
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    clearUser: (state) => {
      state.data = null;
    }
  }
});

export const { setUser, clearUser } = userInfoSlice.actions;
export default userInfoSlice.reducer;