import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import FlagIcon from '../FlagIcon';
import { Helmet } from 'react-helmet';
import QueryForm from '../QueryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';
interface ResolveHostnameProps { }

interface ASNResult {
    ipAddress: string;
    asn: number;
    organization: string;
}


interface CountryResult {
    ipAddress: string;
    countryCode: string;
    countryName: string;
}

const ResolveHostname: React.FC<ResolveHostnameProps> = () => {
    const { hostname } = useParams<{ hostname: string }>();
    const { openSignIn } = useClerk();
    const userInfo = useSelector((state: RootState) => state.user.data);
    const [ipAddresses, setIpAddresses] = useState<string[]>([]);
    const [ipInfo, setIpInfo] = useState<Map<string, { asn: number, organization: string, country: string, countryCode: string }>>(new Map());
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const handleResolveHostname = async () => {
            if (hostname) {
                setLoading(true);
                setError(null);

                try {
                    const response = await fetch('/api/ResolveHostname', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ host: hostname }),
                    });

                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`);
                    }

                    const jsonResponse = await response.json();
                    const ARecords: { address: string; ttl: number }[] = jsonResponse.ARecords;
                    const AAAARecords: { address: string; ttl: number }[] = jsonResponse.AAAARecords;
                    const ipAddresses = [...ARecords.map((record) => record.address), ...AAAARecords.map((record) => record.address)];
                    setIpAddresses(ipAddresses);

                    const asnResponse = await fetch('/api/GetASN', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ ipAddresses }),
                    });

                    const countryResponse = await fetch('/api/GetCountry', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ ipAddresses }),
                    });

                    const asnJson = await asnResponse.json();
                    const countryJson = await countryResponse.json();

                    const ipInfoMap = new Map<string, { asn: number, organization: string, country: string, countryCode: string }>();

                    asnJson.asnResults.forEach((result: ASNResult) => {
                        ipInfoMap.set(result.ipAddress, { asn: result.asn, organization: result.organization, country: '', countryCode: '' });
                    });

                    countryJson.countryResults.forEach((result: CountryResult) => {
                        const currentData = ipInfoMap.get(result.ipAddress);
                        if (currentData) {
                            ipInfoMap.set(result.ipAddress, { ...currentData, country: result.countryName, countryCode: result.countryCode });
                        }
                    });

                    setIpInfo(ipInfoMap);

                } catch (error) {
                    console.log(error);
                    setError('An error occurred while fetching data. Please try again.');
                } finally {
                    setLoading(false);
                }
            }
        };
        if (hostname) {
            if (!userInfo) {
              openSignIn({
                redirectUrl: `/address/${hostname}`,
              });
              return;
            }
        handleResolveHostname();
        }
    }, [hostname,openSignIn,userInfo]);

    const pageTitle = hostname ? `Get IP address for ${hostname} - DNS Lookup Tool` : "DNS Lookup Tool - Lookup IPs of Domains Easily";
    const pageDescription = "DNS Lookup, Resolve Hostname, IP Address Finder, Domain IP Lookup, DNS Query, Reverse DNS Lookup";

    if (!hostname) {
        return (
            <>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="keywords" content={pageDescription} />
                    <link rel="canonical" href={`https://netquery.tools/address/${hostname ? hostname : ''}`} />
                </Helmet>
                <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto">
                        <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faNetworkWired} className="text-blue-500" /> Find IP Address for Domain Names</h1>
                        <QueryForm placeholder="Enter a domain..." ariaLabel="Enter a domain for IP lookup" navigatePath={(inputValue) => `/address/${inputValue}`} />
                        <div className="mt-8">
                            <h2 className="text-3xl font-bold text-gray-800">Frequently Asked Questions</h2>
                            <dl className="mt-4 space-y-6">
                                <div>
                                    <dt className="text-lg font-medium text-gray-900">What is DNS Lookup?</dt>
                                    <dd className="mt-2 text-base text-gray-500">DNS Lookup is a process used to convert a hostname (such as www.example.com) into an IP address.</dd>
                                </div>
                                <div>
                                    <dt className="text-lg font-medium text-gray-900">How does DNS Lookup work?</dt>
                                    <dd className="mt-2 text-base text-gray-500">DNS Lookup works by querying DNS servers to find the IP addresses associated with a domain name.</dd>
                                </div>
                                <div>
                                    <dt className="text-lg font-medium text-gray-900">What is a Reverse DNS Lookup?</dt>
                                    <dd className="mt-2 text-base text-gray-500">Reverse DNS Lookup is the process of querying DNS to determine the domain name associated with an IP address.</dd>
                                </div>
                                <div>
                                    <dt className="text-lg font-medium text-gray-900">How to troubleshoot DNS Lookup failure?</dt>
                                    <dd className="mt-2 text-base text-gray-500">To troubleshoot DNS Lookup failure, check for typos in the domain name, verify your DNS server settings, and ensure there are no network connectivity issues.</dd>
                                </div>
                                <div>
                                    <dt className="text-lg font-medium text-gray-900">What are DNS Forward and Reverse Lookup Zones?</dt>
                                    <dd className="mt-2 text-base text-gray-500">Forward Lookup Zones map domain names to IP addresses, while Reverse Lookup Zones map IP addresses to domain names.</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto my-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Resolved IP Addresses for <span className='text-blue-600'>{hostname}</span></h2>
            {error && <div className="text-red-600 border-l-4 border-red-600 p-4 rounded">{error}</div>}
            {loading && <div className="flex justify-center items-center"><i className="fas fa-spinner fa-spin text-lg text-blue-600"></i>Loading...</div>}
            {!loading && ipAddresses.length > 0 && (
                <div>
                    <div className="overflow-x-auto md:overflow-visible">
                        <table className="w-full text-sm text-left text-gray-500 hidden md:table">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3 px-6">IP Address</th>
                                    <th scope="col" className="py-3 px-6">ASN</th>
                                    <th scope="col" className="py-3 px-6">Organization</th>
                                    <th scope="col" className="py-3 px-6">Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ipAddresses.map((ipAddress, index) => (
                                    <tr className="hover:bg-gray-50" key={index}>
                                        <td className="border-b border-gray-200 p-3">
                                            <Link to={`/ptr/${ipAddress}`} className="text-blue-500 hover:text-blue-700">{ipAddress}</Link>
                                        </td>
                                        <td className="border-b border-gray-200 p-3">
                                            <Link to={`/asntoip/${ipInfo.get(ipAddress)?.asn}`} className="text-blue-500 hover:text-blue-700">
                                                {ipInfo.get(ipAddress)?.asn}
                                            </Link>
                                        </td>
                                        <td className="border-b border-gray-200 p-3">{ipInfo.get(ipAddress)?.organization}</td>
                                        <td className="border-b border-gray-200 p-3 flex items-center">
                                            <FlagIcon countryCode={ipInfo.get(ipAddress)?.countryCode || ''} className="mr-2" aria-label={ipInfo.get(ipAddress)?.country || 'Unknown country'} />
                                            {ipInfo.get(ipAddress)?.country}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                        <div className="md:hidden">
                            {ipAddresses.map((ipAddress, index) => {
                                const info = ipInfo.get(ipAddress);
                                return (
                                    <div key={index} className="bg-gray-50 rounded my
-2 p-3 hover:bg-gray-100">
                                        <div className="flex items-center justify-between">
                                            <div className="font-semibold">IP Address</div>
                                            <div><Link to={`/ptr/${ipAddress}`} className="text-blue-500 hover:text-blue-700">{ipAddress}</Link></div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="font-semibold">ASN</div>
                                            <div><Link to={`/asntoip/${ipInfo.get(ipAddress)?.asn}`} className="text-blue-500 hover:text-blue-700">
                                                {info?.asn}
                                            </Link></div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="font-semibold">Organization</div>
                                            <div>{info?.organization}</div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="font-semibold">Country</div>
                                            <div className="flex items-center">
                                                <FlagIcon countryCode={info?.countryCode || ''} className="mr-2" aria-label={info?.country || 'Unknown country'} />
                                                {info?.country}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};

export default ResolveHostname;