// components/TCPPing.tsx
import { useClerk } from '@clerk/clerk-react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/features/store/store';

interface TCPPingAttempt {
  attempt: number;
  time: string;
}

interface TCPPingTestResult {
  host: string;
  port: number;
  success: boolean;
  attempts: TCPPingAttempt[];
}


type PingParams = {
  [key: string]: string | undefined;
};

const TCPPing: React.FC = () => {
  const { host, port, retryCount, interval } = useParams<PingParams>();
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  const [tcpPingResult, setTcpPingResult] = useState<TCPPingTestResult | null>(null);

  useEffect(() => {
    // Validate all parameters are provided
    if (host && port && retryCount && interval) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `/tcpping/${host}/${port}/${retryCount}/${interval}`,
        });
        return;
      }
      const fetchData = async () => {
        try {
          const response = await fetch('/api/GetTCPPing', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            // Ensure to parse numeric values as they are expected to be numbers on the server side
            body: JSON.stringify({
              host,
              port: parseInt(port, 10),
              retryCount: parseInt(retryCount, 10),
              interval: parseInt(interval, 10),
            }),
          });

          if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
          }

          const jsonResponse = await response.json();
          setTcpPingResult(jsonResponse);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    } else {
      // Handle the case where not all parameters are present
      console.error('All parameters (host, port, retryCount, interval) are required');
    }
  }, [host, port, retryCount, interval, userInfo, openSignIn]);

  return (
    <div className="max-w-2xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">TCP Ping Test</h2>
      {tcpPingResult && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Ping Results</h3>
          <p className="font-medium text-gray-600">Host: <span className="font-normal">{tcpPingResult.host}</span></p>
          <p className="font-medium text-gray-600">Port: <span className="font-normal">{tcpPingResult.port}</span></p>
          <p className="font-medium text-gray-600">Success: <span className="font-normal">{tcpPingResult.success ? 'Yes' : 'No'}</span></p>
          <div className="mt-4">
            {tcpPingResult.attempts.map((attempt, index) => (
              <p key={index} className="text-gray-600">Attempt {attempt.attempt}: <span className="font-semibold">{attempt.time}</span></p>
            ))}
          </div>
        </div>
      )}
      {!tcpPingResult && <p>Loading</p>}
      <h2>Understanding TCP Ping: A Gateway to Network Health</h2>
      <p>In the intricate web of network diagnostics and troubleshooting, TCP Ping emerges as a somewhat unsung hero. Unlike the more commonly known ICMP Ping, TCP Ping uses TCP (Transmission Control Protocol) packets to test the accessibility of a network host. This method proves particularly useful in environments where ICMP packets are blocked or filtered, a common scenario in many corporate networks.</p>

      <p>TCP Ping operates by establishing a connection using the TCP protocol, typically on port 80 or 443, which are commonly open for HTTP and HTTPS traffic, respectively. This technique is akin to knocking on a door and listening for a response; it not only confirms the host's availability but also tests the pathway to it.</p>

      <p>Understanding TCP Ping's role is crucial for network administrators, IT professionals, and even end-users looking to diagnose connectivity issues. Its ability to mimic regular web traffic offers a more accurate reflection of network health, especially in heavily filtered environments. By grasping the fundamentals of TCP Ping, one can unlock a deeper understanding of network diagnostics, paving the way for more effective troubleshooting and maintenance.</p>

      <p>sIn the subsequent sections, we will delve deeper into the workings of TCP Ping, comparing it with ICMP Ping, exploring its real-world applications, and offering tips to harness its full potential. Whether you're a seasoned network professional or just starting, this exploration of TCP Ping will equip you with valuable insights into maintaining a healthy and robust network.</p>
    </div>
  );
};

export default TCPPing;