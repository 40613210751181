import { Certificate } from "@common/Certificate";
import React, { useState, useEffect } from "react";

interface ValidUntilProps {
  certificate: Certificate;
}

const getExpirationString = (valid_to: string): string => {
  const now = new Date();
  const expirationDate = new Date(valid_to);
  const diffTime = expirationDate.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const months = Math.floor(diffDays / 30);
  const days = diffDays % 30;

  let expirationString = "";
  if (months > 0) {
    expirationString += `${months} month${months > 1 ? "s" : ""}`;
  }
  if (days > 0) {
    if (months > 0) {
      expirationString += " and ";
    }
    expirationString += `${days} day${days > 1 ? "s" : ""}`;
  }
  return expirationString;
};

const ValidUntil = ({ certificate }: ValidUntilProps) => {
  const [expiration, setExpiration] = useState("");

  useEffect(() => {
    if (!certificate?.valid_to) {
      return;
    }
    setExpiration(getExpirationString(certificate.valid_to));
  }, [certificate]);

  return (
    <>
      <div>
        Valid until: {certificate?.valid_to} (Expires in {expiration})
      </div>
    </>
  );
};

export default ValidUntil;