import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useClerk } from '@clerk/clerk-react';
import { RootState } from 'src/features/store/store';
import { setLookupType } from 'src/features/lookupType/lookupTypeSlice';
import LookupControls from './LookupControl';
import FeatureOverview from '../FeatureOverview';
import './styles.css';

const MainForm: React.FC = () => {
  const [input, setInput] = useState('');
  const [isCommandPopupVisible, setIsCommandPopupVisible] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.data);
  const navigate = useNavigate();
  const { openSignIn } = useClerk();
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const lookupType = useSelector((state: RootState) => state.lookupType.lookupType);
  const commandDescriptions = {
    cert: "Check TLS/SSL certificate for a domain",
    address: "Lookup IP address information",
    mx: "Find mail exchange servers for a domain",
    dns: "Check Domain Name System records",
    spf: "Inspect Sender Policy Framework records",
    bimi: "Verify Brand Indicators for Message Identification records",
    dkim: "Inspect DomainKeys Identified Mail records",
    "validate-dns": "Validate DNS configuration",
    ptr: "Perform a reverse DNS lookup",
    asn: "Lookup Autonomous System Number information",
    blacklist: "Check if an IP is blacklisted",
    country: "Find the country of an IP",
    whois: "Retrieve registered domain information",
    asntoip: "Convert ASN to IP address"
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);

    if (value === "/") {
      setIsCommandPopupVisible(true);
    } else {
      setIsCommandPopupVisible(false);
      const splitValue = value.split(':');
      if (splitValue.length > 1 && Object.keys(commandDescriptions).includes(splitValue[0])) {
        let [lookup, ...val] = splitValue;
        if (lookup === "mx") {
          lookup = "mx-lookup";
        }
        dispatch(setLookupType(lookup));
        setInput(val.join(":"));
      }
    }
  };

  const handleCommandClick = (command: string) => {
    const commandKey = command === "mx" ? "mx-lookup" : command;
    dispatch(setLookupType(commandKey));
    setInput("");
    setIsCommandPopupVisible(false);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userInfo) {
      await openSignIn({ redirectUrl: `/${lookupType}/${input}` });
      return;
    }
    if (input.trim().length === 0) {
      setErrorMessage('Please enter a valid input.');
      return;
    }
    let hostname = input;
    let port = '443';
    let retryCount = '4';
    let interval = '500';
    if (lookupType === 'tcpping') {
      const parts = hostname.split(':');
      hostname = parts[0];
      port = parts.length > 1 ? parts[1] : port;
      navigate(`/${lookupType}/${hostname}/${port}/${retryCount}/${interval}`);
    } else {
      navigate(`/${lookupType}/${hostname}`);
    }
  };

  const handleLookupTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setLookupType(e.target.value));
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center w-full p-5 gap-5">
        <div className="form-content flex flex-col w-full max-w-md md:max-w-lg relative">
          <form>
            <div className="mb-4">
              <input id="hostname-input" type="text" value={input} onChange={handleInputChange} required className="bg-white border border-gray-300 rounded-lg py-2 px-4 block w-full shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" placeholder="Enter command (e.g., mx:x.com) or type / for list…" aria-label="Query input" />
              {isCommandPopupVisible && (
                <div className="absolute bg-white border border-gray-300 rounded-lg shadow-lg p-4 mt-1 z-10 w-full">
                  {Object.entries(commandDescriptions).map(([command, description]) => (
                    <div key={command} className="p-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleCommandClick(command)}>
                      <strong>{command}:</strong> {description}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <LookupControls handleSubmit={handleSubmit} handleChange={handleLookupTypeChange} />
            <div className="mt-4">
              <a href="https://accounts.netquery.tools/sign-up" className="text-sm text-blue-600 hover:text-blue-800 hover:underline">
                Don't have an account? Sign up now!
              </a>
            </div>
          </form>
        </div>
        <div className="hero-image-container hidden md:block md:flex-1 max-w-lg">
          <img alt="Network Infrastructure" src="/hero.webp" className="w-full" />
        </div>
      </div>
      <FeatureOverview />
    </div>
  );
};


export default MainForm;
