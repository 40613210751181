export const validateBIMIRecord = (record: string): boolean => {
  // Check if record starts with 'v=BIMI1;'
  return record.startsWith("v=BIMI1;");
};

export const validateLogo = async (
  url: string
): Promise<{ isValid: boolean; reasons: string[]; warnings: string[] }> => {
  try {
    const response = await fetch("/api/GetSVG", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
    });

    if (response.status === 204) {
      return {
        isValid: false,
        reasons: ["The URL for the logo does not return an SVG."],
        warnings: [],
      };
    }

    const { svgContent } = await response.json();
    const reasons: string[] = [];
    const warnings: string[] = [];

    console.log("SVG Content: " + svgContent);

    // Check for required elements and attributes in the SVG content
    const baseProfileRegex = /<svg[^>]*baseProfile="tiny-ps"/;
    const versionRegex = /<svg[^>]*version="1.2"/;
    const titleRegex = /<title>[^<]*<\/title>/;
    const descRegex = /<desc>[^<]*<\/desc>/;
    const xAttributeRegex = /<svg[^>]*\sx="[^"]*"/;
    const yAttributeRegex = /<svg[^>]*\sy="[^"]*"/;
    const externalLinksRegex = /<a[^>]*xlink:href="[^"]*"[^>]*>/;
    const scriptsRegex = /<script[^>]*>[\s\S]*?<\/script>/;

    if (!baseProfileRegex.test(svgContent)) {
      reasons.push(
        'Missing or incorrect "baseProfile" attribute in the <svg> element.'
      );
    }
    if (!versionRegex.test(svgContent)) {
      reasons.push(
        'Missing or incorrect "version" attribute in the <svg> element.'
      );
    }
    if (!titleRegex.test(svgContent)) {
      reasons.push("Missing <title> element in the SVG.");
    }
    if (!descRegex.test(svgContent)) {
      warnings.push(
        "Missing <desc> element in the SVG. It should be included to support accessibility."
      );
    }
    if (xAttributeRegex.test(svgContent)) {
      reasons.push(
        'The "x" attribute is not allowed within the <svg> root element.'
      );
    }
    if (yAttributeRegex.test(svgContent)) {
      reasons.push(
        'The "y" attribute is not allowed within the <svg> root element.'
      );
    }
    if (externalLinksRegex.test(svgContent)) {
      reasons.push(
        "The SVG contains external links or references, which are not allowed."
      );
    }
    if (scriptsRegex.test(svgContent)) {
      reasons.push(
        "The SVG contains scripts, animations, or interactive elements, which are not allowed."
      );
    }

    // Additional checks for image size, aspect ratio, and background can be added if necessary.

    return {
      isValid: reasons.length === 0,
      reasons,
      warnings,
    };
  } catch (error) {
    console.error("Error validating logo:", error);
    return {
      isValid: false,
      reasons: ["An error occurred while validating the logo."],
      warnings: [],
    };
  }
};
