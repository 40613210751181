// DomainForm.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface QueryFormProps {
  navigatePath: (inputValue: string) => string;
  placeholder: string;
  ariaLabel: string;
}

const QueryForm: React.FC<QueryFormProps> = ({ navigatePath, placeholder, ariaLabel }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(navigatePath(inputValue));
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center gap-3 w-full">
      <input
        id="hostname-input"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        required
        className="flex-grow bg-white border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-lg py-2 px-4 transition duration-150 ease-in-out"
        placeholder={placeholder}
        aria-label={ariaLabel}
      />
      <button
        type="submit"
        className="flex-shrink-0 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg transition duration-150 ease-in-out"
      >
        Query
      </button>
    </form>
  );

};

export default QueryForm;
