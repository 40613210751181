import React from "react";
import { Certificate } from "@common/Certificate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import EllipticCurveInfo from "./EllipticCurveInfo";
import ValidUntil from "./ValidUntil";

interface CertificateInfoProps {
  certificate: Certificate;
}

function parseSubjectAltName(subjectaltname?: string): string[] {
  if (!subjectaltname) {
    return [];
  }
  const names = subjectaltname.split(/,\s*/);
  return names.map((name) => name.replace(/^DNS:/, ""));
}

const CertificateInfo = ({ certificate }: CertificateInfoProps) => {
  const alternativeNames = parseSubjectAltName(certificate?.subjectaltname);
  return (
    <div className="bg-white p-4 rounded-md shadow space-y-4">
      <h1 className="text-lg font-semibold flex items-center space-x-2">
        <FontAwesomeIcon icon={faLock} className="text-blue-500" />
        <span>{certificate?.subject.CN}</span>
      </h1>

      {certificate?.ca && (
        <p className="flex items-center text-green-600">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
          This is a Certificate Authority (CA)
        </p>
      )}

      <div className="bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">General Information</h2>
        <p className="text-sm text-gray-600">
          <span className="font-medium text-gray-700">Serial Number:</span> {certificate?.serialNumber}
        </p>
        <p className="text-sm text-gray-600 mt-2">
          <span className="font-medium text-gray-700">Valid from:</span> {certificate?.valid_from}
        </p>
        <div className="text-sm text-gray-600 mt-2">
          <ValidUntil certificate={certificate} />
        </div>
        {alternativeNames.length > 0 && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Alternative Names ({alternativeNames.length.toLocaleString()}):</span> {alternativeNames.join(", ")}
          </p>
        )}
      </div>

      <div className="bg-white p-4 shadow rounded-lg mb-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Subject Details</h2>
        <p className="text-sm text-gray-600">
          <span className="font-medium text-gray-700">Subject:</span> {certificate?.subject.CN}
        </p>
        {certificate?.subject.C && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Country:</span> {certificate.subject.C}
          </p>
        )}
        {certificate?.subject.ST && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">State/Province:</span> {certificate.subject.ST}
          </p>
        )}
        {certificate?.subject.L && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Locality:</span> {certificate.subject.L}
          </p>
        )}
        {certificate?.subject.O && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Organization:</span> {certificate.subject.O}
          </p>
        )}
        {certificate?.subject.OU && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Organizational Unit:</span> {certificate.subject.OU}
          </p>
        )}
      </div>
      <div className="bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Issuer Details</h2>
        <p className="text-sm text-gray-600">
          <span className="font-medium text-gray-700">Issuer:</span> {certificate?.issuer.CN}
        </p>
        {certificate?.issuer.C && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Issuer Country:</span> {certificate.issuer.C}
          </p>
        )}
        {certificate?.issuer.ST && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Issuer State/Province:</span> {certificate.issuer.ST}
          </p>
        )}
        {certificate?.issuer.L && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Issuer Locality:</span> {certificate.issuer.L}
          </p>
        )}
        {certificate?.issuer.O && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Issuer Organization:</span> {certificate.issuer.O}
          </p>
        )}
        {certificate?.issuer.OU && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Issuer Organizational Unit:</span> {certificate.issuer.OU}
          </p>
        )}
      </div>
      <div className="bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Public Key Information</h2>
        {certificate?.pubkey && (
          <>
            <div className="bg-gray-100 rounded p-3 text-sm font-mono overflow-auto mb-4">
              <p className="font-medium text-gray-700 mb-2">Public Key:</p>
              <pre>{`-----BEGIN PUBLIC KEY-----\n${certificate.pubkey?.data
                .map((byte) => byte.toString(16).padStart(2, '0'))
                .join('')
                .match(/.{1,64}/g)
                ?.join("\n")}\n-----END PUBLIC KEY-----`}</pre>
            </div>
            {certificate?.bits && (
              <p className="text-sm text-gray-600">
                <span className="font-medium text-gray-700">Key Size:</span> {certificate.bits}
              </p>
            )}
            {certificate?.asn1Curve && (
              <p className="text-sm text-gray-600 mt-2">
                <span className="font-medium text-gray-700">Public Key Curve (ASN.1):</span> {certificate.asn1Curve}
              </p>
            )}
            {certificate?.nistCurve && (
              <p className="text-sm text-gray-600 mt-2">
                <span className="font-medium text-gray-700">Public Key Curve (NIST):</span> {certificate.nistCurve}
              </p>
            )}
            {certificate?.exponent && (
              <p className="text-sm text-gray-600 mt-2">
                <span className="font-medium text-gray-700">RSA Exponent:</span> {certificate.exponent}
              </p>
            )}
            {certificate?.modulus && (
              <p className="text-sm text-gray-600 mt-2">
                <span className="font-medium text-gray-700">RSA Modulus:</span> 0x{certificate.modulus}
              </p>
            )}
          </>
        )}
      </div>
      <div className="bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Fingerprints</h2>
        <p className="text-sm text-gray-600">
          <span className="font-medium text-gray-700">Fingerprint (SHA-1):</span> {certificate?.fingerprint}
        </p>
        <p className="text-sm text-gray-600 mt-2">
          <span className="font-medium text-gray-700">Fingerprint (SHA-256):</span> {certificate?.fingerprint256}
        </p>
        {certificate?.fingerprint512 && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Fingerprint (SHA-512):</span> {certificate.fingerprint512}
          </p>
        )}
      </div>

      <div className="bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Extensions</h2>
        {certificate?.ext_key_usage && (
          <p className="text-sm text-gray-600">
            <span className="font-medium text-gray-700">Extended Key Usage:</span>{" "}
            {certificate.ext_key_usage.map((usage) => {
              let usageDescription;
              switch (usage) {
                case "1.3.6.1.5.5.7.3.1":
                  usageDescription = "Server Authentication";
                  break;
                case "1.3.6.1.5.5.7.3.2":
                  usageDescription = "Client Authentication";
                  break;
                case "1.3.6.1.5.5.7.3.3":
                  usageDescription = "Code Signing";
                  break;
                case "1.3.6.1.5.5.7.3.4":
                  usageDescription = "Email Protection";
                  break;
                case "1.3.6.1.5.5.7.3.8":
                  usageDescription = "Time Stamping";
                  break;
                case "1.3.6.1.5.5.7.3.9":
                  usageDescription = "OCSP Signing";
                  break;
                default:
                  usageDescription = usage;
              }
              return usageDescription;
            }).join(", ")}
          </p>
        )}

        {certificate?.infoAccess && (
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium text-gray-700">Authority Information Access:</span>{" "}
            {JSON.stringify(certificate.infoAccess)}
          </p>
        )}
      </div>


      <EllipticCurveInfo certificate={certificate} />
    </div>
  );
};

export default CertificateInfo;

