import React from 'react';

interface FlagIconProps {
    countryCode: string
    className?: string;
}

const FlagIcon: React.FC<FlagIconProps> = ({ countryCode, className }) => {
    if (!countryCode) return null;

    const combinedClassName = `flag-icon flag-icon-${countryCode.toLowerCase()} ${className}`;

    return <span className={combinedClassName}></span>;
};

export default FlagIcon;