import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';

interface LookupControlsProps {
    handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const LookupControls: React.FC<LookupControlsProps> = ({ handleSubmit, handleChange }) => {
    const lookupType = useSelector((state: RootState) => state.lookupType.lookupType);
    
    return (
        <div className="flex flex-row items-center justify-between gap-4">
            <select
                id="lookup-select"
                value={lookupType}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                aria-label="Select a lookup type"
                onChange={handleChange}
            >
                <option value="" disabled>Choose Query Type</option>
                <option value="cert">TLS Certificates &amp; Ciphers Lookup</option>
                <option value="address">Domain IP Address Info</option>
                <option value="mx-lookup">MX Records Lookup</option>
                <option value="dns">Authoritative DNS Query</option>
                <option value="spf">SPF Records Inspector</option>
                <option value="bimi">BIMI Records Checker</option>
                <option value="dkim">DKIM Records Examiner</option>
                <option value="validate-dns">DNS Configuration Verifier</option>
                <option value="ptr">Reverse DNS (PTR) Lookup</option>
                <option value="asn">ASN Info Lookup</option>
                <option value="asntoip">Reverse ASN to IP Lookup</option>
                <option value="whois">Whois Info Lookup</option>
                <option value="country">Country from IP Lookup</option>
                <option value="blacklist">DNS Blacklist Lookup</option>
            </select>
            <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                onClick={handleSubmit}
            >
                Query
            </button>
        </div>
    );
};

export default LookupControls;
